import React, { useState } from "react";
import profile from "../../assets/images/userImg.svg";
import Message from "../../assets/images/feeds/Message.svg";
import Gray_Like_Btn from "../../assets/images/like_btn.svg";
import Green_Like_Btn from "../../assets/images/like_btn_active.svg";
import { Link } from "react-router-dom";
import SubHeader from "../../sharedComponents/SubHeader";


const Feeds = () => {
  const [count, setCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  const handleLikeClick = () => {
    setCount(count + 1);
    setIsLiked(true);
  };

  const NoticeboardCardData = [
    {
      id: 1,

      Name: "Zarin",
      Time: "12:38",
      text: "text",
      new_text: "new text",
      Profie_Image: profile,
      Message_Image: Message,
      Green_Like_Btn: Green_Like_Btn,
      Gray_Like_Btn: Gray_Like_Btn,
    },
    {
      id: 2,

      Name: "Zarin",
      Time: "12:38",
      text: "text",
      new_text: "new text",
      Profie_Image: profile,
      Message_Image: Message,
      Green_Like_Btn: Green_Like_Btn,
      Gray_Like_Btn: Gray_Like_Btn,
    },
    {
      id: 3,

      Name: "Zarin",
      Time: "12:38",
      text: "text",
      new_text: "new text",
      Profie_Image: profile,
      Message_Image: Message,
      Green_Like_Btn: Green_Like_Btn,
      Gray_Like_Btn: Gray_Like_Btn,
    },
    {
      id: 4,

      Name: "Zarin",
      Time: "12:38",
      text: "text",
      new_text: "new text",
      Profie_Image: profile,
      Message_Image: Message,
      Green_Like_Btn: Green_Like_Btn,
      Gray_Like_Btn: Gray_Like_Btn,
    },
    {
      id: 5,

      Name: "Zarin",
      Time: "12:38",
      text: "text",
      new_text: "new text",
      Profie_Image: profile,
      Message_Image: Message,
      Green_Like_Btn: Green_Like_Btn,
      Gray_Like_Btn: Gray_Like_Btn,
    },

    {
      id: 6,

      Name: "Zarin",
      Time: "12:38",
      text: "text",
      new_text: "new text",
      Profie_Image: profile,
      Message_Image: Message,
      Green_Like_Btn: Green_Like_Btn,
      Gray_Like_Btn: Gray_Like_Btn,
    },
  ];

  return (
    <div
      className="cx-main-card"
      data-aos="zoom-in"
      data-aos-delay="300"
      data-aos-duration="900"
      id="Chat"
    >
        <SubHeader />

      <div className="card-body p-0">
        <div className="row">
          {NoticeboardCardData.map((postDetail, index) => (
            <div className="col-md-4" key={index}>
              <Link to={`/FeedsDetails`}>
                <div className="todocardheadesstyle">
                  <div className=" d-flex justify-content-between">
                    <div className="d-flex  ">
                      <img
                        className=" rounded-circle ml-4"
                        src={postDetail.Profie_Image}
                        style={{ height: "45px", width: "45px" }}
                      />
                      <div>
                        <h6 className="Name_Style">
                          {postDetail.Name}
                        </h6>
                        <h6 className="Time_Style text-secondary">
                          {postDetail.Time}
                        </h6>
                      </div>
                    </div>
                    <img
                    className="Gray_Like_Btn"
                    // style={{width:"100px",height:"100px"}}
                      // src={postDetail.Gray_Like_Btn}
                      // onClick={handleLikeClick}
                      // alt="Like Button"
                    />
                    {/* <span>{count}</span> */}
                  </div>
                  <div className="text-view">
                    <div className="text_Style">{postDetail.text}</div>
                    <div className="text_Style1">{postDetail.new_text}</div>
                  </div>

                  <div className="d-flex align-items mt-2">
                    <img src={postDetail.Green_Like_Btn} className="mb-1" />
                    <h6 className="mt-2 text1">0</h6>
                    <img src={postDetail.Message_Image} />
                    <h6 className="mt-2 text2">0</h6>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Feeds;

import React from "react";
import profile from "../../assets/images/userImg.svg";
import Message from "../../assets/images/feeds/Message.svg";
import Gray_Like_Btn from "../../assets/images/like_btn.svg";
import Green_Like_Btn from "../../assets/images/like_btn_active.svg";

const FeedsDetails = () => {
  return (
    <main id="cx-main" className="Assignment">
      <div className="cx-main-card">
        <div
          className="main-assignment-wrapper"
          data-aos="zoom-in"
          data-aos-delay="300"
          data-aos-duration="900"
        >
          {/* <h5>FeedsDetails</h5> */}

          <div className="assignment-details-wrapper">
            <div className=" d-flex justify-content-between">
              <div className="d-flex  ">
                <img
                  className=" rounded-circle ml-4"
                  src={profile}
                  style={{ height: "45px", width: "45px" }}
                />
                <div>
                  <h6 className="Name_Style">Zarin</h6>
                  <h6 className="Time_Style text-secondary">12:38</h6>
                </div>
              </div>
              <div>
                <img 
                    className="Gray_Like_Btn"

                // src={Gray_Like_Btn}
                 />
              </div>
            </div>
            <div className="text-view">
              <div className="text_Style">text</div>
              <div className="text_Style1">new text</div>
            </div>

            <div className="d-flex align-items mt-2">
              <img src={Green_Like_Btn} className="mb-1" />
              <h6 className="mt-2 text1">0</h6>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default FeedsDetails;

import React, { useState } from "react";

import Notification from "../../assets/images/Notification/notification.svg";
import SubHeader from "../../sharedComponents/SubHeader";


const MyDiary_Notification = () => {
  const NoticeboardCardData = [
    {
      id: 1,
      Notifications:
        "A diary titled Test has been assignment to your kid Mayank.Please Check it.",
      Time: "31 Jan 12:38 PM",
      Notification_Image: Notification,
    },
    {
      id: 2,
      Notifications:
        "A diary titled Test Count in parent app has been assigned to your kid Mayank. please Check it.",
      Time: "31 Dec 12:38 PM",
      Notification_Image: Notification,
    },
    {
      id: 3,
      Notifications:
        "A diary titled Test has been assignment to your kid Mayank.Please Check it.",
      Time: "31 oct 12:38 PM",
      Notification_Image: Notification,
    },
    {
      id: 4,
      Notifications:
        "A diary titled Test Count in parent app has been assigned to your kid Mayank. please Check it.",
      Time: "31 Jul 12:38 PM",
      Notification_Image: Notification,
    },
    {
      id: 5,
      Notifications:
        "A diary titled Test has been assignment to your kid Mayank.Please Check it.",
      Time: "31 Feb 12:38 PM",
      Notification_Image: Notification,
    },

    {
      id: 6,
      Notifications:
        "A diary titled Test Count in parent app has been assigned to your kid Mayank. please Check it.",
      Time: "31 Aug 12:38 PM",
      Notification_Image: Notification,
    },
  ];

  return (
    <div
      className="cx-main-card"
      data-aos="zoom-in"
      data-aos-delay="300"
      data-aos-duration="900"
      id="Chat"
    >
      
      <div className="card-body p-0">
      <SubHeader />

        <div className="row">
          {NoticeboardCardData.map((postDetail, index) => (
            <div className="col-md-4" key={index}>
              <div className="Notification_header">
                <div className="d-flex">
                  <img
                    src={postDetail.Notification_Image}
                    className="notification_image"
                  />
                  <div className="Notification_text">
                    {postDetail.Notifications}
                  </div>
                </div>
                <h6 className="Notification_dateTime">{postDetail.Time}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MyDiary_Notification;

// i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Import translations
import translationEN from "./translationEN.json";
import translationAR from "./translationAR.json";

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationAR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  interpolation: {
    escapeValue: false, // React already protects from XSS
  },
});

export default i18n;

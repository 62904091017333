import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import ic_attendance from "../assets/images/ic_attendance.svg";
import ic_setting from "../assets/images/ic_setting.svg";
import ic_communication from "../assets/images/ic_communication.svg";
import comm from "../assets/images/comm.svg";

import ic_bus_tracking from "../assets/images/ic_bus_tracking.svg";
import ic_cashless from "../assets/images/ic_cashless.svg";
import ic_administrator from "../assets/images/ic_administrator.svg";
import ic_academics from "../assets/images/ic_academics.svg";
import ic_dashboard from "../assets/images/ic_dashboard.svg";
import ic_logout from "../assets/images/ic_logout.svg";
import ic_arrow_sidebar from "../assets/images/ic_arrow_sidebar.svg";
import { Accordion, Button, Dropdown } from "react-bootstrap";
import logo from "../assets/images/logo.svg";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const Sidebar = () => {
  const navigate = useNavigate();
  const currentRoute = useLocation().pathname;
  const [activeSidebar, setActiveSidebar] = useState("");
  const [attendance, setAttendance] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const [openMode, setOpenMode] = useState(false);

  const { sidebar, setSidebar } = useContext(AppContext);

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const { t } = useTranslation();
  // useEffect(() => {
  //   MobileSidebar();
  // }, [isMobile]);

  const MobileSidebar = () => (isMobile ? setSidebar(false) : setSidebar(true));
  return (
    <main className="cx-sidebar">
      <div
        className={
          sidebar
            ? "cx-sidebar-wrapper cx-active-sidebar"
            : "cx-sidebar-wrapper"
        }
      >
        <div className="brand-logo">
          <Link to="/Dashboard">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="sidebar-button">
          <button onClick={() => setSidebar(!sidebar)}>
            <img
              src={ic_arrow_sidebar}
              alt=""
              className={sidebar ? "" : "img-arrow-active"}
            />
          </button>
        </div>
        <div className="menus-wrapper">
          <Accordion className="sidebar-accordion">
            {/* ================== > DASHBOARD START <================== */}

            <Link
              to="/Dashboard"
              className="d-block menu-link"
              onClick={() => (isMobile ? setSidebar(true) : setSidebar(false))}
            >
              <div
                // onClick={()=>{setDashboard(true);
                //   setAttendance(false)}}
                className={
                  currentRoute === "/Dashboard"
                    ? // dashboard
                      "menu-outer-warpper activeMenu"
                    : "menu-outer-warpper"
                }
              >
                <div className="icon">
                  <img
                    // src={ic_dashboard}
                    alt=""
                    className="dashboard_icon"
                  />
                </div>
                <div className="menu-name">{t("Dashboard")}</div>
                {/* <p>{t("Dashboard")}</p> */}
              </div>
            </Link>
            {/* ================== > DASHBOARD START <================== */}

            {/* ================== > ATTENDENCE START <================== */}

            <Accordion.Item
              onClick={() => {
                setAttendance(!attendance);
                setDashboard(false);
              }}
              eventKey="0"
              className={
                currentRoute === "/Calendar" ||
                currentRoute === "/Leaves" ||
                currentRoute === "/ApplyLeave" ||
                currentRoute === "/EditLeave" ||
                currentRoute === "/AttendanceDetails" ||
                currentRoute === "/Attendane_Notification"
                  ? "activeMenu"
                  : ""
              }
            >
              <Accordion.Header>
                <div className="menu-outer-warpper">
                  <div className="icon">
                    <img
                      src={ic_attendance}
                      alt=""
                      className="attendance_icon"
                    />
                  </div>
                  <div className="menu-name">{t("Attendance")}</div>{" "}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="sub-menus">
                  <ul>
                    <li
                      className={
                        currentRoute === "/Calendar" && "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Calendar"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Calendar")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/AttendanceDetails" &&
                        "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/AttendanceDetails"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Attendance Details")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/Leaves" ||
                        currentRoute === "/ApplyLeave" ||
                        currentRoute === "/EditLeave"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Leaves"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Leaves")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/Attendane_Notification" &&
                        "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Attendane_Notification"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Notifications")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            {/* ================== > ATTENDENCE END <================== */}

            {/* ================== > Academics START <================== */}
            <Accordion.Item
              eventKey="1"
              className={
                currentRoute === "/Curriculum" ||
                currentRoute === "/Topic" ||
                currentRoute === "/Assignment" ||
                currentRoute === "/DailyAssignmentwithoutReview" ||
                currentRoute === "/DailyAssignmentwithReview" ||
                currentRoute === "/IncompleteAssignment" ||
                currentRoute === "/Assessment" ||
                currentRoute === "/DailyAssessmentwithoutReview" ||
                currentRoute === "/IncompleteAssesment" ||
                currentRoute === "/Assessment" ||
                currentRoute === "/DailyAssessmentwithoutReview" ||
                currentRoute === "/Elearning" ||
                currentRoute === "/ElearningSubjectTopic" ||
                currentRoute === "/ElearningAdditionalTopic" ||
                currentRoute === "/StudyMaterial" ||
                currentRoute === "/StudyMaterialTopicDetails" ||
                currentRoute === "/Courses" ||
                currentRoute === "/AboutCourseDetails" ||
                currentRoute === "/BuyCourseDetails" ||
                currentRoute === "/Academic_Notification"
                  ? "activeMenu"
                  : ""
              }
            >
              <Accordion.Header>
                <div className="menu-outer-warpper">
                  <div className="icon">
                    <img
                      // src={ic_academics}
                      alt=""
                      className="academics_icon"
                    />
                  </div>
                  <div className="menu-name">{t("Academics")}</div>{" "}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="sub-menus">
                  <ul>
                    <li
                      className={
                        currentRoute === "/Curriculum" ||
                        currentRoute === "/Topic"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Curriculum"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Curriculum")}
                      </Link>
                    </li>

                    <li
                      className={
                        currentRoute === "/Assignment" ||
                        currentRoute === "/DailyAssignmentwithoutReview" ||
                        currentRoute === "/DailyAssignmentwithReview" ||
                        currentRoute === "/IncompleteAssignment"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Assignment"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Assignment")}
                      </Link>
                    </li>

                    <li
                      className={
                        currentRoute === "/Assessment" ||
                        currentRoute === "/IncompleteAssesment" ||
                        currentRoute === "/DailyAssessmentwithoutReview"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Assessment"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Assessment")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/Elearning" ||
                        currentRoute === "/ElearningAdditionalTopic" ||
                        currentRoute === "/ElearningSubjectTopic"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Elearning"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("E-Learning")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/StudyMaterial" ||
                        currentRoute === "/StudyMaterialTopicDetails"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/StudyMaterial"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Study Material")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/Courses" ||
                        currentRoute === "/AboutCourseDetails" ||
                        currentRoute === "/BuyCourseDetails"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Courses"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Courses")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/Academic_Notification"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Academic_Notification"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Notifications")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            {/* ================== > Academics END <================== */}

            {/* ================== > Administration START <================== */}
            <Accordion.Item
              eventKey="2"
              className={
                currentRoute === "/MyKids" ||
                currentRoute === "/IdCards" ||
                currentRoute === "/EditKidsDetails" ||
                currentRoute === "/EditRegistration" ||
                currentRoute === "/NewRegistration" ||
                currentRoute === "/ViewKidsDetails" ||
                currentRoute === "/ViewRegistration" ||
                currentRoute === "/Fees" ||
                currentRoute === "/document" ||
                currentRoute === "/Certificate" ||
                currentRoute === "/InvoiceFee" ||
                currentRoute === "/TransferCertificate" ||
                currentRoute === "/RequestNewTc" ||
                currentRoute === "/InvoiceReceipt" ||
                currentRoute === "/Administration_Notification"
                  ? "activeMenu"
                  : ""
              }
            >
              <Accordion.Header>
                <div className="menu-outer-warpper">
                  <div className="icon">
                    <img
                      //  src={ic_administrator}
                      alt=""
                      className="administrator_icon"
                    />
                  </div>
                  <div className="menu-name">{t("Administration")}</div>{" "}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="sub-menus">
                  <ul>
                    <li
                      className={
                        currentRoute === "/MyKids" ||
                        currentRoute === "/NewRegistration" ||
                        currentRoute === "/ViewKidsDetails" ||
                        currentRoute === "/IdCards" ||
                        currentRoute === "/ViewRegistration" ||
                        currentRoute === "/EditRegistration" ||
                        currentRoute === "/EditKidsDetails"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/MyKids"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("My Kids")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/Fees" ||
                        currentRoute === "/InvoiceFee" ||
                        currentRoute === "/InvoiceReceipt"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Fees"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Fees")}
                      </Link>
                    </li>

                    <li
                      className={
                        (currentRoute === "/TransferCertificate" ||
                          currentRoute == "/RequestNewTc") &&
                        "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/TransferCertificate"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Transfer Certificate")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/document" ||
                        currentRoute === "/Certificate"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/document"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Documents")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/Administration_Notification"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Administration_Notification"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Notifications")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            {/* ================== > Administration END <================== */}

            {/* ================== > Cashless Wallet START <================== */}
            <Link to="/CashlessWallet" className="d-block menu-link">
              <div
                // onClick={()=>{setDashboard(true);
                //   setAttendance(false)}}
                className={
                  currentRoute === "/CashlessWallet" ||
                  currentRoute === "/RechargeWallet"
                    ? // dashboard
                      "menu-outer-warpper activeMenu"
                    : "menu-outer-warpper"
                }
              >
                <div className="icon">
                  <img
                    // src={ic_cashless}
                    alt=""
                    className="cashless_icon"
                  />
                </div>
                <div className="menu-name">{t("Cashless Wallet")}</div>{" "}
              </div>
            </Link>
            {/* ================== > Cashless Wallet END <================== */}

            {/* ================== > Bus Tracking START <================== */}
            <Accordion.Item
              eventKey="3"
              className={
                currentRoute === "/BusTracking" ||
                currentRoute === "/AuthorisedPerson" ||
                currentRoute === "/ViewAuthorisedPerson" ||
                currentRoute === "/CreateAuthorisedPerson" ||
                currentRoute === "/EditAuthorisedPerson" ||
                currentRoute === "/BusRoute" ||
                currentRoute === "/RenewTransportation" ||
                currentRoute === "/BusTracking_Notification"
                  ? "activeMenu"
                  : ""
              }
            >
              <Accordion.Header>
                <div className="menu-outer-warpper">
                  <div className="icon">
                    <img
                      // src={ic_bus_tracking}
                      className="bus_icon"
                      alt=""
                    />
                  </div>
                  <div className="menu-name">{t("Bus Tracking")}</div>{" "}
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="sub-menus">
                  <ul>
                    <li
                      className={
                        currentRoute === "/BusTracking" && "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/BusTracking"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Track Bus")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/BusRoute" && "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/BusRoute"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Route")}
                      </Link>
                    </li>

                    <li
                      className={
                        (currentRoute === "/AuthorisedPerson" ||
                          currentRoute === "/CreateAuthorisedPerson" ||
                          currentRoute === "/EditAuthorisedPerson" ||
                          currentRoute === "/ViewAuthorisedPerson") &&
                        "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/AuthorisedPerson"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("AuthorisedPerson")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/RenewTransportation" &&
                        "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/RenewTransportation"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Renew Transportation")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/BusTracking_Notification" &&
                        "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/BusTracking_Notification"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Notifications")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            {/* ================== > Bus Tracking END <================== */}

            {/* ================== > Communication START <================== */}
            <Accordion.Item
              eventKey="4"
              className={
                currentRoute === "/Chat" ||
                currentRoute === "/ComposeMessage" ||
                currentRoute === "/NoticeBoard" ||
                currentRoute === "/NoticeDetail" ||
                currentRoute === "/VideoConference" ||
                currentRoute === "/Feeds" ||
                currentRoute === "/Notifications"
                  ? "activeMenu"
                  : ""
              }
            >
              <Accordion.Header>
                <div className="menu-outer-warpper">
                  <div className="icon">
                    <img
                      // src={ic_communication}
                      className="communication_icon"
                      alt=""
                    />
                  </div>

                  <div className="menu-name">{t("Communication")}</div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="sub-menus">
                  <ul>
                    <li
                      className={
                        (currentRoute === "/Chat" ||
                          currentRoute === "/ComposeMessage") &&
                        "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Chat"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Chat")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/VideoConference" && "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/VideoConference"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("VideoConference")}
                      </Link>
                    </li>
                    <li
                      className={
                        (currentRoute === "/NoticeBoard" ||
                          currentRoute === "/NoticeDetail") &&
                        "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/NoticeBoard"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Notice Board")}
                      </Link>
                    </li>
                    <li
                      className={
                        (currentRoute === "/Feeds" ||
                          currentRoute === "/NoticeDetail") &&
                        "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Feeds"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Feeds")}
                      </Link>
                    </li>
                    <li
                      className={
                        (currentRoute === "/Notifications" ||
                          currentRoute === "/NoticeDetail") &&
                        "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/Notifications"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Notifications")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            {/* ================== > Communication END <================== */}

            {/* ================== > My Dairy START <================== */}
            {/* <Link to="/MyDiary" className="d-block menu-link">
              <div
                // onClick={()=>{setDashboard(true);
                //   setAttendance(false)}}
                className={
                  currentRoute === "/MyDiary"
                    ? // dashboard
                      "menu-outer-warpper activeMenu"
                    : "menu-outer-warpper"
                }
              >
                <div className="icon">
                  <img src={ic_cashless} alt="" />
                </div>
                <div className="menu-name">My Dairy</div>
              </div>
            </Link> */}
            <Accordion.Item
              eventKey="5"
              className={
                currentRoute === "/MyDiary1" ||
                currentRoute === "/MyDiary_Notification"
                  ? "activeMenu"
                  : ""
              }
            >
              <Accordion.Header>
                <div className="menu-outer-warpper">
                  <div className="icon">
                    <img src={ic_communication} alt="" />
                  </div>
                  <div className="menu-name">{t("MyDiary")}</div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="sub-menus">
                  <ul>
                    <li
                      className={
                        currentRoute === "/MyDiary1" && "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/MyDiary1"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("MyDiary")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/MyDiary_Notification" &&
                        "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/MyDiary_Notification"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Notifications")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Accordion.Body>
            </Accordion.Item>

            {/* My Dairy 1 */}
            {/* <Link to="/MyDiary1" className="d-block menu-link">
              <div
                // onClick={()=>{setDashboard(true);
                //   setAttendance(false)}}
                className={
                  currentRoute === "/MyDiary1"
                    ? // dashboard
                      "menu-outer-warpper activeMenu"
                    : "menu-outer-warpper"
                }
              >
                <div className="icon">
                  <img src={ic_cashless} alt="" />
                </div>
                <div className="menu-name">My Dairy 1</div>
              </div>
            </Link> */}
            {/* ================== > My Dairy End <================== */}

            {/* ================== > Settings START <================== */}
            <Accordion.Item
              eventKey="6"
              className={
                currentRoute === "/PersonalDetails" ||
                currentRoute === "/ChangePassword" ||
                currentRoute === "/EditProfile" ||
                currentRoute === "/NotificationSetting"
                  ? "activeMenu"
                  : ""
              }
            >
              <Accordion.Header>
                <div className="menu-outer-warpper">
                  <div className="icon">
                    <img
                      // src={ic_setting}
                      alt=""
                      className="setting-icon"
                    />
                  </div>
                  <div className="menu-name">{t("Settings")}</div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="sub-menus">
                  <ul>
                    <li
                      className={
                        currentRoute === "/PersonalDetails" ||
                        currentRoute === "/EditProfile"
                          ? "activeMenuList"
                          : ""
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/PersonalDetails"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Personal Details")}
                      </Link>
                    </li>
                    <li
                      className={
                        currentRoute === "/ChangePassword" && "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/ChangePassword"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Change Password")}
                      </Link>
                    </li>
                    {/* <li
                      className={
                        currentRoute === "#" && "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="#"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        Language
                      </Link>
                    </li> */}
                    <li
                      className={
                        currentRoute === "/NotificationSetting" &&
                        "activeMenuList"
                      }
                    >
                      <span className="menu-circle"></span>
                      <Link
                        to="/NotificationSetting"
                        onClick={() =>
                          isMobile ? setSidebar(true) : setSidebar(false)
                        }
                      >
                        {t("Notification Settings")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Accordion.Body>
              {/* <Accordion.Header>
              <div className="icon_settings mb-3">
              <img src={comm} alt="" />

                  </div>
                  </Accordion.Header> */}
            </Accordion.Item>
            {/* ================== > Settings END <================== */}
          </Accordion>

          {/* <div className="logout">
            <Link
              to="#"
              className="d-block menu-link"
              onClick={() => setOpenMode(true)}
            >
              <div className={"menu-outer-warpper"}>
                <div className="icon">
                  <img src={ic_logout} alt="" />
                </div>
                <div className="menu-name">Logout</div>
              </div>
            </Link>
          </div> */}
        </div>
      </div>
      <Modal
        size="md"
        centered
        show={openMode}
        onHide={() => setOpenMode(false)}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Log Out")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="borderModal"></div>
          <p>{t("Are you sure you want to Log Out ?")}</p>
          <div className="borderModalBelow"></div>
        </Modal.Body>
        <Modal.Footer>
          <div className="modalBtns">
            <button className="cancelBtn" onClick={() => setOpenMode(false)}>
              {t("Cancel")}
            </button>
            <button className="cancelBtn cx-ml-15">
              <Link to="/">{t("Log Out")}</Link>
            </button>
          </div>
        </Modal.Footer>
        
      </Modal>
    </main>
  );
};

export default Sidebar;

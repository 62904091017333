import React, { useState } from "react";

import Notification from "../../assets/images/Notification/notification.svg";
import SubHeader from "../../sharedComponents/SubHeader";

const Academic_Notification = () => {
  


  const NoticeboardCardData = [
    {
      id: 1,

      Notifications:
      "New assignment added for Neha pethkar, should be submitted on or before 14-04-2022",

      Time: "31 Jan 12:38 PM",

      Notification_Image: Notification,
    },
    {
      id: 2,

    
      Notifications:
        "New assignment added for Neha pethkar, should be submitted on or before 14-04-2022",
      Time: "31 Dec 12:38 PM",

      Notification_Image: Notification,
    },
    {
      id: 3,

    
      Notifications:
      "New assignment added for Neha pethkar, should be submitted on or before 14-04-2022",

      Time: "31 oct 12:38 PM",

      Notification_Image: Notification,
    },
    {
      id: 4,

    
      Notifications:
      "New assignment added for Neha pethkar, should be submitted on or before 14-04-2022",


      Time: "31 Jul 12:38 PM",

      Notification_Image: Notification,
    },
    {
      id: 5,

    
      Notifications:
      "New assignment added for Neha pethkar, should be submitted on or before 14-04-2022",

      Time: "31 Feb 12:38 PM",

      Notification_Image: Notification,
    },

    {
      id: 6,

    
      Notifications:
      "New assignment added for Neha pethkar, should be submitted on or before 14-04-2022",

      Time: "31 Aug 12:38 PM",

      Notification_Image: Notification,
    },
  ];

  return (
    <div
      className="cx-main-card"
      data-aos="zoom-in"
      data-aos-delay="300"
      data-aos-duration="900"
      id="Chat"
    >
   
      <div className="card-body p-0">
      <SubHeader />

        <div className="row">
          {NoticeboardCardData.map((postDetail, index) => (
            <div className="col-md-4" key={index}>
              <div className="Notification_header">
                <div className="d-flex">
                    <img src={postDetail.Notification_Image} className="notification_image" />
                    <div  className="Notification_text">{postDetail.Notifications}</div>
                </div>
                <h6 className="Notification_dateTime">{postDetail.Time}</h6>

              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Academic_Notification;

import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import cart_icon from "../../src/assets/images/cart_icon.svg";
import purple_calendar from "../../src/assets/images/purple_calendar.svg";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

const SubHeader = () => {
  const currentPath = useLocation().pathname;
  const [openMode, setOpenMode] = useState(false);
  const navigate = useNavigate();
  const [date, setDate] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [value, onChange] = useState(new Date());
  const [isShown, setIsShown] = useState(false);
  const { t } = useTranslation();

  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);

    // 👇️ or simply set it to true
    // setIsShown(true);
  };

  return (
    <div className="breadcrumb-wrapper cx-mb-20">
      {(currentPath === "/PersonalDetails" && (
        <div className="breadcrumb">{t("Settings")}</div>
      )) ||
        (currentPath === "/EditProfile" && (
          <div div className="breadcrumb">
            <Link to="/PersonalDetails" className="belowLink">
              {t("Settings")}
            </Link>
            <p>/ Edit Personal Details</p>
          </div>
        )) ||
        (currentPath === "/ChangePassword" && (
          <div div className="breadcrumb">
            <p>{t("Change Password")}</p>
          </div>
        )) ||
        (currentPath === "/VideoConference" && (
          <div div className="breadcrumb">
            <p>{t("Video Conference")}</p>
          </div>
        )) ||
        (currentPath === "/NoticeBoard" && (
          <div div className="breadcrumb">
            <p>{t("Notice Board")}</p>
          </div>
        )) ||
        (currentPath === "/NotificationSetting" && (
          <div div className="breadcrumb">
            <p>{t("Notification")}</p>
          </div>
        )) ||
        // Administration routes
        (currentPath === "/MyKids" && (
          <>
            <div div className="breadcrumb">
              <p>{t("My Kids")}</p>
            </div>
            <div className="btn-wrapper">
              <Link to="/NewRegistration" className="green-btn-1">
                <p>{t("Register New Kid")}</p>
              </Link>
            </div>
          </>
        )) ||
        (currentPath === "/Assignment" ||
        currentPath === "/DailyAssignmentwithReview" ||
        currentPath === "/DailyAssignmentwithoutReview" ||
        currentPath === "/IncompleteAssignment" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Assignment")}</p>
            </div>
            {/* <div className="btn-wrapper">
              <p>Daily Assignment </p>
            </div> */}
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/Assessment" ||
        currentPath === "/DailyAssessmentwithoutReview" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Assessment")}</p>
            </div>
            {/* <div className="btn-wrapper">
              <p>Daily Assignment </p>
            </div> */}
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/IncompleteAssesment" ? (
          <>
            <div className="breadcrumb">
              <Link className="belowLink" to="/Assessment">
                Assessment
              </Link>
              <p>/ Incomplete Assesment</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/Curriculum" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Curriculum")}</p>
            </div>
            <div className="btn-wrapper">
              <div className="data-class">
                {t("Start Date")} : &nbsp;
                <span className="date-wrapper"> 10/03/2022</span>
                <div className="date-space"></div>
                {t("End Date")} : &nbsp;
                <span className="date-wrapper"> 20/01/2023</span>
              </div>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/Topic" ? (
          <>
            <div className="breadcrumb">
              <Link className="belowLink" to="/Curriculum">
                Curriculum
              </Link>
              <p>/ English</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/Calendar" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Calendar")}</p>
            </div>
            <div className="btn-wrapper">
              <Link to="/AttendanceDetails" className="green-btn-1">
                {t("View Day Wise Attendance")}
              </Link>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/AttendanceDetails" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Attendance Details")}</p>
            </div>
            <div className="attendance-status">
              <div className="percentage">
                <div className="color-icon bg-blue"></div>
                <div className="text-box">
                  <p>
                    {t("Attendance")}
                    <span>90%</span>
                  </p>
                </div>
              </div>
              <div className="days">
                <div className="color-icon bg-green"></div>
                <div className="text-box">
                  <p>
                    {t("Attendance Days")}
                    <span>23</span>
                    <span>/26</span>
                  </p>
                </div>
              </div>
              <div className="leavedays">
                <div className="color-icon bg-red"></div>
                <div className="text-box">
                  <p>
                    {t("Leave Days")}
                    <span>0</span>
                  </p>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/Leaves" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Leaves")}</p>
            </div>
            <div className="btn-wrapper">
              <Link to="/ApplyLeave" className="green-btn-1">
                {t("Apply Leave")}
              </Link>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/BusTracking" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Track Bus")}</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/BusRoute" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Route")}</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/RenewTransportation" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Renew Transportation")}</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/AuthorisedPerson" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Authorised Person")}</p>
            </div>
            <div className="btn-wrapper">
              <Link to="/CreateAuthorisedPerson" className="green-btn-1">
                {t("Create Authorised Person")}
              </Link>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/chat" ? (
          <>
            <div className="breadcrumb">
              <p>{t("chat")}</p>
            </div>
            <div className="btn-wrapper">
              <Link to="/ComposeMessage" className="green-btn-1">
                {t("Compose Message")}
              </Link>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/ViewAuthorisedPerson" ? (
          <>
            <div className="breadcrumb">
              <Link to="/AuthorisedPerson">
                <p>{t("Authorised Person")}</p>
              </Link>
            </div>
            <div className="btn-wrapper">
              <Link to="/CreateAuthorisedPerson" className="green-btn-1">
                {t("Create Authorised Person")}
              </Link>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/CreateAuthorisedPerson" ? (
          <>
            <div className="breadcrumb">
              <Link to="/AuthorisedPerson">
                <p>{t("Authorised Person")}</p>
              </Link>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/MyDiary" ? (
          <>
            <div className="breadcrumb">
              <p>{t("My Diary")}</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/MyDiary1" ? (
          <>
            <div className="breadcrumb">
              <p>{t("My Diary New")}</p>
            </div>
            <div className="btn-wrapper">
              <img src={purple_calendar} alt="" onClick={handleClick} />
              {/* {isShown && ( */}
              <DatePicker
                selected={startDate}
                // placeholderText="Select Date"
                onChange={(date) => setStartDate(date)}
                className="date-picker-input"
              />
              {/* )} */}
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/EditAuthorisedPerson" ? (
          <>
            <div className="breadcrumb">
              <Link to="/AuthorisedPerson">
                <p>{t("AuthorisedPerson")}</p>
              </Link>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/ApplyLeave" ? (
          <>
            <div className="breadcrumb">
              <Link to="/Leaves" className="belowLink">
                {t("Leaves")}
              </Link>
              <p>/ Apply Leave</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/EditLeave" ? (
          <>
            <div className="breadcrumb">
              <Link to="/Leaves" className="belowLink">
                {t("Leaves")}
              </Link>
              <p> / Edit Leave</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/EditKidsDetails" ? (
          <>
            <div className="breadcrumb">
              <Link to="/MyKids" className="belowLink">
                {t("My Kids")}
              </Link>
              <p> / Edit Kids Details</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/EditRegistration" ? (
          <>
            <div className="breadcrumb">
              <Link to="/MyKids" className="belowLink">
                {t("Registration")}
              </Link>
              <p> / Edit Kids Registration</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/NewRegistration" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Registration")}</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/NoticeDetail" ? (
          <>
            <div className="breadcrumb">
              <Link to="/NoticeBoard" className="belowLink">
                {t("Notice Board")}
              </Link>
              <p> / Notice Detail</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/ViewKidsDetails" ? (
          <>
            <div className="breadcrumb">
              <Link to="/MyKids" className="">
                {t("My Kids")}
              </Link>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/Chat" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Chat")}</p>
            </div>
            <div className="btn-wrapper">
              <Link to="/ComposeMessage" className="green-btn-1">
                {t("Compose Message")}
              </Link>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/ViewRegistration" ? (
          <>
            <div className="breadcrumb">
              <Link to="/MyKids" className="belowLink">
                <span> {t("Registration")}</span>
              </Link>
              <p>/ Student Details</p>
            </div>
            <div className="btn-wrapper">
              <Link to="/NewRegistration" className="green-btn-1">
                {t("Register New Kid")}
              </Link>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/ComposeMessage" ? (
          <>
            <div className="breadcrumb">
              <p>
                <span className="belowLink">{t("Chat")}</span> / Compose Message
              </p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/IdCards" && (
          <>
            <div div className="breadcrumb">
              <Link to="/MyKids" className="belowLink">
                {t("My Kids")}
              </Link>
              <p>/ Id Card</p>{" "}
            </div>

            <div className="attendance-status">
              <Link
                to="#"
                onClick={() => setOpenMode(!openMode)}
                className="breadCrumbLink"
              >
                <button className="cx-btn-1">{t("Apply New Card")}</button>
              </Link>
            </div>

            <Modal
              size="md"
              centered
              show={openMode}
              onHide={() => setOpenMode(false)}
              animation={true}
            >
              <Modal.Header closeButton>
                <Modal.Title>{t("New Card ?")}</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="borderModal"></div>
                <p>
                  {t(
                    "Are you sure you want to apply for the new card, this may cost you?"
                  )}
                </p>
                <div className="borderModalBelow"></div>
              </Modal.Body>

              <Modal.Footer>
                <div className="modalBtns">
                  <button
                    className="cancelBtn"
                    onClick={() => setOpenMode(false)}
                  >
                    {t("Cancel")}
                  </button>
                  <button className="YesBtn">{t("Yes")}</button>
                </div>
              </Modal.Footer>
            </Modal>
          </>
        )) ||
        (currentPath === "/Elearning" && (
          <>
            <div className="breadcrumb">
              <p>{t("E-Learning")}</p>
            </div>
          </>
        )) ||
        (currentPath === "/ElearningAdditionalTopic" && (
          <>
            <div className="breadcrumb">
              <Link to="/Elearning" className="belowLink">
                <span className="belowLink" style={{ color: "#954386" }}>
                  E-Learning / Additional E-Learning Material
                </span>
              </Link>
              <p style={{ color: "#954386" }}>/ English</p>
            </div>
          </>
        )) ||
        (currentPath === "/ElearningSubjectTopic" ? (
          <>
            <div className="breadcrumb">
              <Link
                to="/Elearning"
                className="belowLink"
                style={{ color: "#954386" }}
              >
                <span className="belowLink" style={{ color: "#954386" }}>
                  {t("E-Learning")}
                </span>
              </Link>
              <p>/ Physics</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/StudyMaterial" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Study Material")}</p>
            </div>
            <div className="cart-btn" onClick={() => navigate("/mycart")}>
              <img src={cart_icon} alt="" />
              <span className="noti-count">7</span>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/StudyMaterialTopicDetails" ? (
          <>
            <div className="breadcrumb">
              <Link to="/StudyMaterial">
                <span className="belowLink" style={{ color: "#954386" }}>
                  {t("Study Material")}
                </span>
              </Link>
              <p style={{ color: "#954386" }}>/ Topic Details</p>
            </div>
            <div className="cart-btn" onClick={() => navigate("/mycart")}>
              <img src={cart_icon} alt="" />
              <span className="noti-count">7</span>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/mycart" ? (
          <>
            <div className="breadcrumb">
              <Link to="/StudyMaterial" className="belowLink">
                <span style={{ color: "#954386" }}>{t("Study Material")}</span>
              </Link>
              <p style={{ color: "#954386" }}> / My Cart</p>
            </div>
            <div className="cart-btn" onClick={() => navigate("/mycart")}>
              <img src={cart_icon} alt="" />
              <span className="noti-count">7</span>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/Courses" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Courses")}</p>
            </div>
            <div className="cart-btn" onClick={() => navigate("/mycart")}>
              <img src={cart_icon} alt="" />
              <span className="noti-count">7</span>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/AboutCourseDetails" ? (
          <>
            <div className="breadcrumb">
              <Link to="/Courses" className="belowLink">
                <span style={{ color: "#954386" }}>{t("Courses")}</span>
              </Link>
              <p style={{ color: "#954386" }}>/ Rutherford's Atomic Model</p>
            </div>
            <div className="cart-btn" onClick={() => navigate("/mycart")}>
              <img src={cart_icon} alt="" />
              <span className="noti-count">7</span>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/BuyCourseDetails" ? (
          <>
            <div className="breadcrumb">
              <p>
                <span className="belowLink">{t("Courses")}</span>/ Rutherford’s
                Atomic Model
              </p>
            </div>
            <div className="cart-btn" onClick={() => navigate("/mycart")}>
              <img src={cart_icon} alt="" />
              <span className="noti-count">7</span>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/Fees" ? (
          <>
            <div className="breadcrumb">
              <p> {t("Fees")}</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/TransferCertificate" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Transfer Certificate")}</p>
            </div>
            <div className="attendance-status">
              <Link to="/RequestNewTc">
                <button className="cx-btn-1">{t("Apply")}</button>
              </Link>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/RequestNewTc" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Transfer Certificate")}</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/document" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Documents")}</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/Certificate" ? (
          <>
            {/* <div className="breadcrumb">
              <p>Documents</p>
            </div> */}
            <div className="breadcrumb">
              <Link to="/document" className="belowLink">
                <span>{t("Documents")}</span>
              </Link>
              <p>/ Certificate</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/CashlessWallet" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Cashless Wallet")}</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/RechargeWallet" ? (
          <>
            <div className="breadcrumb">
              <Link to="/CashlessWallet" className="belowLink">
                <span>{t("Cashless Wallet")}</span>
              </Link>
              <p>/ Recharge Wallet</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/InvoiceFee" ? (
          <>
            <div className="breadcrumb">
              <Link to="/Fees" className="belowLink">
                <span>{t("Fee Invoice")}</span>
              </Link>
              <p>/ Invoice</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/Attendane_Notification" ||
        currentPath === "/Academic_Notification" ||
        currentPath === "/Administration_Notification" ||
        currentPath === "/Notifications" ||
        currentPath === "/BusTracking_Notification" ||
        currentPath === "/MyDiary_Notification" ? (
          <>
            <div className="breadcrumb">
              <p>{t("Notification")}</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/Feeds" ? (
          <>
            <div className="breadcrumb">
              <p>Feeds</p>
            </div>
          </>
        ) : (
          ""
        )) ||
        (currentPath === "/InvoiceReceipt" ? (
          <>
            <div className="breadcrumb">
              <Link to="/Fees" className="belowLink">
                <span>{t("Fee Receipt")}</span>
              </Link>
              <p>/ Receipt</p>
            </div>
          </>
        ) : (
          ""
        ))}
      <></>
    </div>
  );
};
export default SubHeader;

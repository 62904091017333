import React, { useState } from "react";

import Notification from "../../assets/images/Notification/notification.svg";
import SubHeader from "../../sharedComponents/SubHeader";

const BusTracking_Notification = () => {
  const NoticeboardCardData = [
    {
      id: 1,

      Notifications:
      "Mayank pradeep boarded the bus Motovehiclr atGWFP+MJG, vivekanand nagar  Kesavadasapuram, thiruvananthapuram, Kerala  695006,india safely on 2024-02-01 @ 1041:16",

      Time: "31 Jan 12:38 PM",

      Notification_Image: Notification,
    },
    {
      id: 2,

      Notifications:
      "Mayank pradeep boarded the bus Motovehiclr atGWFP+MJG, vivekanand nagar  Kesavadasapuram, thiruvananthapuram, Kerala  695006,india safely on 2024-02-01 @ 1041:16",


      Time: "31 Dec 12:38 PM",

      Notification_Image: Notification,
    },
    {
      id: 3,

      Notifications:
      "Mayank pradeep boarded the bus Motovehiclr atGWFP+MJG, vivekanand nagar  Kesavadasapuram, thiruvananthapuram, Kerala  695006,india safely on 2024-02-01 @ 1041:16",


      Time: "31 oct 12:38 PM",

      Notification_Image: Notification,
    },
    {
      id: 4,

      Notifications:
      "Mayank pradeep boarded the bus Motovehiclr atGWFP+MJG, vivekanand nagar  Kesavadasapuram, thiruvananthapuram, Kerala  695006,india safely on 2024-02-01 @ 1041:16",


      Time: "31 Jul 12:38 PM",

      Notification_Image: Notification,
    },
    {
      id: 5,

      Notifications:
        "Mayank pradeep boarded the bus Motovehiclr atGWFP+MJG, vivekanand nagar  Kesavadasapuram, thiruvananthapuram, Kerala  695006,india safely on 2024-02-01 @ 1041:16",

      Time: "31 Feb 12:38 PM",

      Notification_Image: Notification,
    },

    {
      id: 6,

      Notifications:
      "Mayank pradeep boarded the bus Motovehiclr atGWFP+MJG, vivekanand nagar  Kesavadasapuram, thiruvananthapuram, Kerala  695006,india safely on 2024-02-01 @ 1041:16",


      Time: "31 Aug 12:38 PM",

      Notification_Image: Notification,
    },
  ];

  return (
    <div
      className="cx-main-card"
      data-aos="zoom-in"
      data-aos-delay="300"
      data-aos-duration="900"
      id="Chat"
    >
      
      <div className="card-body p-0">
      <SubHeader />
        <div className="row">
          {NoticeboardCardData.map((postDetail, index) => (
            <div className="col-md-4" key={index}>
              <div className="Notification_header">
                <div className="d-flex">
                  <img
                    src={postDetail.Notification_Image}
                    className="notification_image"
                  />
                  <div className="Notification_text">
                    {postDetail.Notifications}
                  </div>
                </div>
                <h6 className="Notification_dateTime">{postDetail.Time}</h6>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BusTracking_Notification;

import React, { useState, useContext, useEffect } from "react";
import Header from "../../sharedComponents/Header";
import Sidebar from "../../sharedComponents/Sidebar";
import DashBoard_top from "../../assets/images/DashBoard_top.svg";
import Kids_profile from "../../assets/images/Kids_profile.svg";
import Kids_Prifile_2 from "../../assets/images/Kids_Prifile_2.jpg";
import GOToProfile from "../../assets/images/GOToProfile.svg";
import Lac_English from "../../assets/images/Lac_English.svg";
import Lac_Math from "../../assets/images/Lac_Math.svg";
import Lac_Science from "../../assets/images/Lac_Science.svg";
import Lac_Social from "../../assets/images/Lac_Social.svg";
import Meet_Pricipale from "../../assets/images/Meet_Pricipale.svg";
import Meet_Group from "../../assets/images/Meet_Group.svg";
import Meet_techer from "../../assets/images/Meet_techer.svg";
import Assingment_Table_Next from "../../assets/images/Assingment_Table_Next.svg";
import view_icon from "../../assets/images/view_icon.svg";
import delete_icon from "../../assets/images/delete_icon.svg";
import { Carousel, Dropdown, ProgressBar } from "react-bootstrap";
import { Link } from "react-router-dom";
import Calendar from "react-calendar";
import { TabPanel, TabView } from "primereact/tabview";
import { AppContext } from "../../context/AppContext";
import MapComponent from "../../sharedComponents/MapComponent";
import { Dialog } from "primereact/dialog";
import { Tooltip } from "primereact/tooltip";
import { withTranslation } from "react-i18next";

const Dashboard = ({ t }) => {
  const [show, setShow] = useState(false);
  const [value, onChange] = useState(new Date());
  const { sidebar, setSidebar } = useContext(AppContext);
  const [renderModal, setRenderModal] = useState(false);

  useEffect(() => {
    setRenderModal(!renderModal);
  }, []);

  return (
    <main id="cx-main" className="Dashboard">
      <div
        className={sidebar ? "cx-main-card" : "cx-main-card"}
        data-aos="zoom-in"
        data-aos-duration="500"
        id="dashboard_main"
      >
        <div className="top_section_1 row g-3">
          <div className="col-xl-8 paddingRight10">
            <div className="top_left ">
              <div className="top_left_up">
                <div className="text">
                  <div className="heading">
                    <span>{t("Welcome Back")} ,</span>{" "}
                    <span className="par-name">Mr. John Doe</span>
                  </div>
                  <div className="sub-heading">
                    {t("Your kids completed")} <span>80%</span>{" "}
                    {t("of their tasks")}
                  </div>
                </div>
                <div className="img">
                  <img src={DashBoard_top} alt="" />
                </div>
              </div>

              <div className="top_left_bottom">
                <div className="My_kids">
                  <p> {t("My Kids")}</p>
                </div>
                <Carousel className="Carousel" interval={null}>
                  <Carousel.Item>
                    <div className="single_kide row g-3">
                      <div className="col-lg-3 border-dash ps-0">
                        <div className="photo ">
                          <img src={Kids_profile} alt="" className="kids_img" />
                          <p className="kids_name">Johnathan Doe</p>
                          <div className="Gender">
                            <p className="mail">M</p>
                            <p className="age">
                              16<span>yrs</span>
                            </p>
                          </div>
                          <Link to="/PersonalDetails">
                            <div>
                              <p> {t("Go to Profile")}</p>
                              <img src={GOToProfile} alt="" />
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-9 ps-xl-3 p-xl-0">
                        <div className="Discription row g-0">
                          <div className="common_label-card col-lg-4">
                            <label htmlFor="">{t("Grade Name")} </label>
                            <p>Plus Two</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Student User ID")}</label>
                            <p>1234</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor="">{t("Assignment Progress")}</label>
                            <ProgressBar now={35} />
                            <p className="progres_percentage">35%</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor="">{t("Division Name")}</label>
                            <p>A</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Mifare Card ID")} </label>
                            <p>456789</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor="">{t("Assignment Progress")}</label>
                            <ProgressBar now={40} />
                            <p className="progres_percentage">40%</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor="">{t("Syllabus")}</label>
                            <p>CBSE English Medium</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Admission Number")}</label>
                            <p>123456789</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Course Progress")} </label>
                            <ProgressBar now={60} />
                            <p className="progres_percentage">60%</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("School Hours")}</label>
                            <p>09:00 - 12:30 pm</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Joining Date")} </label>
                            <p>02-02-2023</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor="">{t("Exam Result")} </label>
                            <p className="result">85.50%</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <div className="single_kide row g-3">
                      <div className="col-lg-3 border-dash">
                        <div className="photo ">
                          <img
                            src={Kids_Prifile_2}
                            alt=""
                            className="kids_img"
                          />
                          <p className="kids_name">Emma Watson</p>
                          <div className="Gender">
                            <p className="mail">F</p>
                            <p className="age">
                              22<span>yrs</span>
                            </p>
                          </div>
                          <Link to="/PersonalDetails">
                            <div>
                              <p>{t("Go to Profile")} </p>
                              <img src={GOToProfile} alt="" />
                            </div>
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-9 ps-xl-3 p-xl-0">
                        <div className="Discription row g-0">
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Grade Name")} </label>
                            <p>One Two</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Student User ID")}</label>
                            <p>875469</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor="">{t("Assignment Progress")}</label>
                            <ProgressBar now={85} />
                            <p className="progres_percentage">85%</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Division Name")}</label>
                            <p>A+</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Mifare Card ID")}</label>
                            <p>123321</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor="">
                              {" "}
                              {t("Assignment Progress")}
                            </label>
                            <ProgressBar now={90} />
                            <p className="progres_percentage">90%</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Syllabus")}</label>
                            <p>CBSE English Medium</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Admission Number")}</label>
                            <p>6395742</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Course Progress")} </label>
                            <ProgressBar now={70} />
                            <p className="progres_percentage">70%</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("School Hours")} </label>
                            <p>09:00 - 12:30 pm</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor=""> {t("Joining Date")} </label>
                            <p>02-02-2023</p>
                          </div>
                          <div className="common_label-card col-lg-4">
                            <label htmlFor="">{t("Exam Result")}</label>
                            <p className="result">95.50%</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
          <div className="col-xl-4 paddingLeft10">
            <div className="top_right ">
              <Calendar value={value} onChange={onChange} />
              <div className="common_tabs">
                <TabView>
                  <TabPanel header={t("Upcoming Lectures")}>
                    <div className="overFlow_X">
                      <div className="singleUpComingMeeting">
                        <div className="left">
                          <img src={Lac_English} alt="" />
                          <div className="subject">
                            <p>English</p>
                            <p className="timeing">02:00 - 03:00 PM</p>
                          </div>
                        </div>
                        <div className="right bk_com">
                          <p className="Completed">Completed</p>
                        </div>
                      </div>
                      <div className="singleUpComingMeeting">
                        <div className="left">
                          <img src={Lac_Math} alt="" />
                          <div className="subject">
                            <p>Mathematics</p>
                            <p className="timeing">02:00 - 03:00 PM</p>
                          </div>
                        </div>
                        <div className="right bk_yelow">
                          <p className="InProgress">In Progress</p>
                        </div>
                      </div>
                      <div className="singleUpComingMeeting">
                        <div className="left">
                          <img src={Lac_Social} alt="" />
                          <div className="subject">
                            <p>Social Studies</p>
                            <p className="timeing">02:00 - 03:00 PM</p>
                          </div>
                        </div>
                        <div className="right bk_red">
                          <p className="YettoStart">Yet to Start</p>
                        </div>
                      </div>
                      <div className="singleUpComingMeeting">
                        <div className="left">
                          <img src={Lac_Science} alt="" />
                          <div className="subject">
                            <p>Science</p>
                            <p className="timeing">02:00 - 03:00 PM</p>
                          </div>
                        </div>
                        <div className="right bk_red">
                          <p className="YettoStart">Yet to Start</p>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel header={t("Meetings")}>
                    <div className="overFlow_X">
                      <div className="singleUpComingMeeting">
                        <div className="left">
                          <img src={Meet_techer} alt="" />
                          <div className="subject">
                            <p>Teacher’s Meeting</p>
                            <p className="timeing">02:00 - 03:00 PM</p>
                          </div>
                        </div>
                        <div className="right ">
                          <p className="">
                            <Link to="/VideoConference">Join Now</Link>
                          </p>
                        </div>
                      </div>
                      <div className="singleUpComingMeeting">
                        <div className="left">
                          <img src={Lac_Math} alt="" />
                          <div className="subject">
                            <p>Mathematics</p>
                            <p className="timeing">02:00 - 03:00 PM</p>
                          </div>
                        </div>
                        <div className="right ">
                          <p className="">
                            <Link to="/VideoConference">Join Now</Link>
                          </p>
                        </div>
                      </div>
                      <div className="singleUpComingMeeting">
                        <div className="left">
                          <img src={Lac_Social} alt="" />
                          <div className="subject">
                            <p>Social Studies</p>
                            <p className="timeing">02:00 - 03:00 PM</p>
                          </div>
                        </div>
                        <div className="right ">
                          <p className="">
                            <Link to="/VideoConference">Join Now</Link>
                          </p>
                        </div>
                      </div>
                      <div className="singleUpComingMeeting">
                        <div className="left">
                          <img src={Lac_Science} alt="" />
                          <div className="subject">
                            <p>Science</p>
                            <p className="timeing">02:00 - 03:00 PM</p>
                          </div>
                        </div>
                        <div className="right">
                          <p className="">
                            <Link to="/VideoConference">Join Now</Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </div>
        </div>

        <div className="top_section_2 row g-3">
          <div className="col-xl-8 mt-0 paddingRight10">
            <div className="Assignments ">
              <div className="head">
                <p className="AssignmentsHeading"> {t("Assignments")}</p>
                {/* <Tooltip target=".ViewAll" id="ToolTipsName" /> */}
                <p
                  className="ViewAll"
                  data-pr-tooltip="Click To see List of Assignments"
                  data-pr-position="right"
                  data-pr-at="right+5 top"
                  data-pr-my="left center-2"
                  style={{ cursor: "pointer" }}
                >
                  <Link to="/Assignment"> {t("View All")}</Link>
                </p>
              </div>
              <div className="tabel-main-wrpper">
                <table className="assignment-custom-table">
                  <thead>
                    <tr>
                      <th className="w10"> {t("Sr.No")}</th>
                      <th className="w15">{t("Subject")} </th>
                      <th className="w25 titleAssisment"> {t("Title")}</th>
                      <th className="w15">{t("Created On")}</th>
                      <th className="w10"> {t("Marks")}</th>
                      <th className="w10"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="serial-number ">1</td>
                      <td>English</td>
                      <td className="titleAssisment">Vocabulary</td>
                      <td>20-02-2023</td>
                      <td>
                        <span className="pending">Pending</span>
                      </td>
                      <td className=" text-center">
                        <img src={Assingment_Table_Next} alt="" />
                      </td>
                    </tr>
                    <tr>
                      <td className="serial-number w10">2</td>
                      <td>Mathematics</td>
                      <td className="titleAssisment">Differential Equations</td>
                      <td>03-01-2023</td>
                      <td>
                        <span className="approved">Approved</span>
                      </td>
                      <td className="text-center">
                        <img src={Assingment_Table_Next} alt="" />
                      </td>
                    </tr>
                    <tr>
                      <td className="serial-number w10">3</td>
                      <td>Sciences</td>
                      <td className="titleAssisment">Forensic Science</td>
                      <td>12-02-2023</td>
                      <td>
                        <span className="approved">Approved</span>
                      </td>
                      <td className=" text-center">
                        <img src={Assingment_Table_Next} alt="" />
                      </td>
                    </tr>
                    <tr>
                      <td className="serial-number">4</td>
                      <td>Geography</td>
                      <td className="titleAssisment">
                        Earth's surface and the Plane Surface
                      </td>
                      <td>20-02-2023</td>
                      <td>
                        <span className="approved">Approved</span>
                      </td>
                      <td className=" text-center">
                        <img src={Assingment_Table_Next} alt="" />
                      </td>
                    </tr>
                    <tr>
                      <td className="serial-number ">5</td>
                      <td>History</td>
                      <td className="titleAssisment">presentation</td>
                      <td>10-12-2022</td>
                      <td>
                        <span className="rejected">Rejected</span>
                      </td>
                      <td className=" text-center">
                        <img src={Assingment_Table_Next} alt="" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-xl-4 mt-0 paddingLeft10">
            <div className="NoticeBoard ">
              <div className="head">
                <p className="AssignmentsHeading"> {t("Notice Board")}</p>
                <p className="ViewAll">
                  <Link to="/NoticeBoard">{t("View All")}</Link>
                </p>
              </div>
              <div className="Notice_List">
                <div className="List">
                  <img src={Lac_Math} alt="" />
                  <div className="subject">
                    <p>Parents-Teacher Meeting</p>
                    <p className="Date">Date-02-02-2023</p>
                  </div>
                </div>
                <div className="List">
                  <img src={Lac_Math} alt="" />
                  <div className="subject">
                    <p>Exam Results</p>
                    <p className="Date">Date-02-02-2023</p>
                  </div>
                </div>
                <div className="List">
                  <img src={Lac_Math} alt="" />
                  <div className="subject">
                    <p>Cultural Activities</p>
                    <p className="Date">Date-02-02-2023</p>
                  </div>
                </div>
                <div className="List">
                  <img src={Lac_Math} alt="" />
                  <div className="subject">
                    <p>Science Events</p>
                    <p className="Date">Date-28-03-2023</p>
                  </div>
                </div>
                <div className="List">
                  <img src={Lac_Math} alt="" />
                  <div className="subject">
                    <p>Scholar Meet</p>
                    <p className="Date">Date-18-01-2023</p>
                  </div>
                </div>
                <div className="List">
                  <img src={Lac_Math} alt="" />
                  <div className="subject">
                    <p>Get Together Invitation</p>
                    <p className="Date">Date-02-02-2023</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="top_section_2">
          <div className="Assignments col-xl-8">
            <div className="head">
              <p className="AssignmentsHeading"> {t("Assessment")}</p>
              <p className="ViewAll">
                <Link to="/Assessment">{t("View All")}</Link>
              </p>
            </div>
            <div className="tabel-main-wrpper">
              <table>
                <thead>
                  <tr>
                    <th className="w10"> {t("Sr.No")}</th>
                    <th className="w15">{t("Subject")} </th>
                    <th className="w25 titleAssisment"> {t("Title")}</th>
                    <th className="w15">{t("Created On")}</th>
                    <th className="w10"> {t("Marks")}</th>
                    <th className="w10"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="serial-number">1</td>
                    <td className="titleAssisment">English</td>
                    <td className="titleAssisment">Mr. John Doe </td>
                    <td>20-02-2023</td>
                    <td>
                      <span className="pending-number">55</span>
                    </td>
                    <td className="text-center">
                      <img src={Assingment_Table_Next} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td className="serial-number">2</td>
                    <td className="titleAssisment">Mathematics</td>
                    <td className="titleAssisment">Mr. Smith</td>
                    <td>05-02-2023</td>
                    <td>
                      <span className="approved-number">62</span>
                    </td>
                    <td className="text-center">
                      <img src={Assingment_Table_Next} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td className="serial-number">3</td>
                    <td className="titleAssisment">Sciences</td>
                    <td className="titleAssisment">Mr. Shane Watson</td>
                    <td>17-02-2023</td>
                    <td>
                      <span className="pending-number">47</span>
                    </td>
                    <td className="text-center">
                      <img src={Assingment_Table_Next} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td className="serial-number">4</td>
                    <td className="titleAssisment">Geography</td>
                    <td className="titleAssisment">Mr. Michael Clarke</td>
                    <td>20-02-2023</td>
                    <td>
                      <span className="approved-number">77</span>
                    </td>
                    <td className="text-center">
                      <img src={Assingment_Table_Next} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td className="serial-number">5</td>
                    <td className="titleAssisment">History</td>
                    <td className="titleAssisment">Ms. Lee Furlong</td>
                    <td>27-12-2022</td>
                    <td>
                      <span className="rejected-number">10</span>
                    </td>
                    <td className="text-center">
                      <img src={Assingment_Table_Next} alt="" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="top_section_2">
          <div className="Assignments col-xl-8">
            <div className="head">
              <p className="AssignmentsHeading">{t("Course")}</p>
              <p className="ViewAll">
                <Link to="/Courses">{t("View All")}</Link>
              </p>
            </div>
            <div className="tabel-main-wrpper">
              <table>
                <thead>
                  <tr>
                    <th className="w10"> {t("Sr.No")}</th>
                    <th className="w15">{t("Subject")}</th>
                    <th className="w25">{t("Title")}</th>
                    <th className="w30">{t("Progress")} </th>
                    <th className="w10"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="serial-number">1</td>
                    <td className="titleAssisment">English</td>
                    <td className="titleAssisment">Mr. Timothy Winter</td>
                    <td className="process_yellow">
                      <label htmlFor="">55%</label>
                      <ProgressBar now={55} />
                    </td>
                    <td>
                      <img
                        src={Assingment_Table_Next}
                        className="ms-4"
                        alt=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="serial-number">2</td>
                    <td className="titleAssisment">Mathematics</td>
                    <td className="titleAssisment">Mr. Robert Agranoff</td>
                    <td className="Full_Green">
                      <label htmlFor="">100%</label>
                      <ProgressBar now={100} />
                    </td>
                    <td>
                      <img
                        src={Assingment_Table_Next}
                        className="ms-4"
                        alt=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="serial-number">3</td>
                    <td>pharmaceutics</td>
                    <td>Mr. Harvard</td>
                    <td className="process_yellow">
                      <label htmlFor="">21%</label>
                      <ProgressBar now={21} />
                    </td>
                    <td>
                      <img
                        src={Assingment_Table_Next}
                        className="ms-4"
                        alt=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="serial-number">4</td>
                    <td className="titleAssisment">Law</td>
                    <td className="titleAssisment">Mr. Muhammad Taqi Usmani</td>
                    <td className="Full_Green">
                      <label htmlFor="">100%</label>
                      <ProgressBar now={100} />
                    </td>
                    <td>
                      <img
                        src={Assingment_Table_Next}
                        className="ms-4"
                        alt=""
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="serial-number">5</td>
                    <td className="titleAssisment">History</td>
                    <td className="titleAssisment">Mr. Fred Fiedler</td>
                    <td className="empty_red">
                      <label htmlFor="">Yet to start</label>
                      <ProgressBar now={0} />
                    </td>
                    <td>
                      <img
                        src={Assingment_Table_Next}
                        className="ms-4"
                        alt=""
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="top_section_2">
          <div className="Assignments col-xl-8">
            <div className="head">
              <p className="AssignmentsHeading"> {t("Fees")}</p>
              <p className="ViewAll">
                <Link to="/Fees">{t("View All")}</Link>
              </p>
            </div>
            <div className="tabel-main-wrpper">
              <table>
                <thead>
                  <tr>
                    <th className="w15">{t("Sr.No")}</th>
                    <th className="w25">{t("Expense")}</th>
                    <th className="w15">{t("Amount")}</th>
                    <th className="w15"> {t("Date")}</th>
                    <th className="w15"> {t("Status")}</th>
                    <th className="w10"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="serial-number">1</td>
                    <td className="titleAssisment">Law</td>
                    <td className="titleAssisment">1000 Rs</td>
                    <td>20-02-2023</td>
                    <td className="">
                      <span className="pending same-width">Due</span>
                    </td>
                    <td className="paynow text-center">Pay Now</td>
                  </tr>
                  <tr>
                    <td className="serial-number">2</td>
                    <td className="titleAssisment">Strategic Management</td>
                    <td className="titleAssisment">1000 Rs</td>
                    <td>02-02-2023</td>
                    <td className="">
                      <span className="approved same-width">paid</span>
                    </td>
                    <td className="text-center">
                      <img src={Assingment_Table_Next} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td className="serial-number">3</td>
                    <td className="titleAssisment">pharmaceutics</td>
                    <td className="titleAssisment">1000 Rs</td>
                    <td>29-02-2023</td>
                    <td className="">
                      <span className="approved same-width">paid</span>
                    </td>
                    <td className="text-center">
                      <img src={Assingment_Table_Next} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td className="serial-number">4</td>
                    <td className="titleAssisment">political science</td>
                    <td className="titleAssisment">1000 Rs</td>
                    <td>13-01-2023</td>
                    <td className="">
                      <span className="approved same-width">paid</span>
                    </td>
                    <td className="text-center">
                      <img src={Assingment_Table_Next} alt="" />
                    </td>
                  </tr>
                  <tr>
                    <td className="serial-number">5</td>
                    <td className="titleAssisment">English Literature</td>
                    <td className="titleAssisment">1000 Rs</td>
                    <td>12-12-2022</td>
                    <td className="">
                      <span className="rejected">Late Due's</span>
                    </td>
                    <td className="paynow text-center">Pay Now</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {renderModal && (
        <Dialog
          header="Dashboard Details"
          visible={renderModal}
          modal={true}
          style={{ width: "45vw" }}
          headerClassName="head"
          className="head"
          onHide={() => setRenderModal(!renderModal)}
        >
          <div className="dark-theme-dialog">
            <p className="dark-theme-dialog">
              <ul>
                <li>
                  <span>Below are the all tips for dashboard</span>
                </li>
                <li>
                  <span>
                    Welcome card shows the status of remaining course.
                  </span>
                </li>
                <li>
                  <span>
                    By clicking top right icon you can switch languages & logout
                    too.
                  </span>
                </li>
                <li>
                  <span>
                    Below Calendar you can check details of upcoming lectures
                    and meetings.
                  </span>
                </li>
                <li>
                  <span>
                    Dashboard contains complete material like Assesment &
                    Assignment details.
                  </span>
                </li>
              </ul>
            </p>
          </div>
        </Dialog>
      )}
    </main>
  );
};
export default withTranslation()(Dashboard);

import React, { useState } from "react";

import Notification from "../../assets/images/Notification/notification.svg";
import SubHeader from "../../sharedComponents/SubHeader";


const Notifications = () => {
  const [count, setCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  const handleLikeClick = () => {
    setCount(count + 1);
    setIsLiked(true);
  };

  const NoticeboardCardData = [
    {
      id: 1,

      Notifications:
        "wishing you a very Happy Birthday from may you have many more years of happiness and success.",
      Time: "31 Jan 12:38 PM",

      Notification_Image: Notification,
    },
    {
      id: 2,

      Notifications:
        "wishing you a very Happy Birthday from may you have many more years of happiness and success.",
      Time: "31 Jan 12:38 PM",

      Notification_Image: Notification,
    },
    {
      id: 3,

      Notifications:
        "wishing you a very Happy Birthday from may you have many more years of happiness and success.",
      Time: "31 Jan 12:38 PM",

      Notification_Image: Notification,
    },
    {
      id: 4,

      Notifications:
        "wishing you a very Happy Birthday from may you have many more years of happiness and success.",
      Time: "31 Jan 12:38 PM",

      Notification_Image: Notification,
    },
    {
      id: 5,

      Notifications:
        "wishing you a very Happy Birthday from may you have many more years of happiness and success.",
      Time: "31 Jan 12:38 PM",

      Notification_Image: Notification,
    },

    {
      id: 6,

      Notifications:
        "wishing you a very Happy Birthday from may you have many more years of happiness and success.",
      Time: "31 Jan 12:38 PM",

      Notification_Image: Notification,
    },
  ];

  return (
    <div
      className="cx-main-card"
      data-aos="zoom-in"
      data-aos-delay="300"
      data-aos-duration="900"
      id="Chat"
    >
 
      <div className="card-body p-0">
      <SubHeader />

        <div className="row">
          {NoticeboardCardData.map((postDetail, index) => (
            <div className="col-md-4" key={index}>
              <div className="Notification_header">
                <div className="d-flex">
                    <img src={postDetail.Notification_Image} className="notification_image" />
                    <div  className="Notification_text">{postDetail.Notifications}</div>
                </div>
                <h6 className="Notification_dateTime">{postDetail.Time}</h6>

              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
